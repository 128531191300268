/*** IMPORTS FROM imports-loader ***/
import "core-js/modules/es.promise";
import "core-js/modules/es.array.iterator";

import $ from 'jquery';

// ResponsiveBP
import 'responsive-bp/src/js/responsive.core.js';
import 'responsive-bp/src/js/responsive.navigation.js';
import 'responsive-bp/src/js/responsive.dropdown.js';

// Sticky-Kit
import 'sticky-kit/dist/sticky-kit.js';

// Wrap Swiper element
var wrapSwiper = function (element, childrenSelector = null, pagination = false, navigation = false) {
	var $element = $(element);
	$element.children(childrenSelector).addClass('swiper-slide');
	$element.wrapInner('<div class="swiper-wrapper" />');

	if (pagination) {
		$element.append('<div class="swiper-pagination" />');
	}

	if (navigation) {
		$element.append('<a class="icon-only button swiper-button-prev"><i class="icon icon-arrow-left"></i></a>');
		$element.append('<a class="icon-only button swiper-button-next"><i class="icon icon-arrow-right"></i></a>');
	}
};
var hoverSwiper = function (element, swiper) {
	var $element = $(element);

	var autoplayRunning = false;
	$element.hover(function () {
		autoplayRunning = swiper.autoplay.running;
		swiper.autoplay.stop();
	}, function () {
		if (autoplayRunning) {
			swiper.autoplay.start();
		}
	});

	if (swiper.params.autoplay.disableOnInteraction) {
		swiper.on('slideChange', function () {
			autoplayRunning = false;
		});
	}
};

// DOM ready
$(async function () {
	// Responsive menu
	$('[data-navigation]').each(async function () {
		var $nav = $(this),
			$button = $nav.children('button:first-child');

		$('html').on('lock.r.bodylock', function (e) {
			$button.css('margin-right', e.padding);
		}).on('unlock.r.bodylock', function () {
			$button.css('margin-right', 0);
		}).on('click', function (e) {
			if ($(e.target).is('body')) {
				$nav.navigation('hide');
			}
		});
	});

	var header = $('header');
	if (header.length) {
		// Navigation
		var navigation = header.find('.nav-main');
		if (navigation.length) {
			// Add click to open/close
			var menuItems = navigation.find('li.sub').each(function () {
				var $this = $(this), link = $this.find('> a');
				link.on('click', function (e) {
					$this.siblings('li').removeClass('open');
					$this.siblings('li').find('li.sub').removeClass('open');

					if ($this.hasClass('open')) {
						$this.removeClass('open');
						$this.find('li.open').removeClass('open');
						$(document.body).removeClass('mask');
					}
					else {
						$this.addClass('open');
						$(document.body).addClass('mask');
					}

					e.preventDefault();
					e.stopPropagation();
				});
			});

			$(document.body).on('click', function () {
				menuItems.removeClass('open');
				$(document.body).removeClass('mask');
			});
		}

		// Responsive menu
		header.each(function () {
			var responsiveNav = header.find('.responsive-nav');

			var toggler = header.find('.toggler').click(function () {
				responsiveNav.toggleClass('-collapsed');
				toggler.toggleClass('is-active');
				$(document.body).toggleClass('mask');
			});
		});
	}

	// Sitcky side navigation
	var window_width = $(window).width();
	if (window_width < 1200) {
		$(".side-navigation").trigger("sticky_kit:detach");
	} else {
		make_sticky();
	}

	$(window).resize(function () {
		if (window_width < 1200) {
			$(".side-navigation").trigger("sticky_kit:detach");
		} else {
			make_sticky();
		}

	});

	function make_sticky() {
		$(".side-navigation").stick_in_parent({
			parent: '.row',
			offset_top: $('.top').height() + 80 // Top fixed + padding section
		});

		$('.side-navigation')
			.on('sticky_kit:bottom', function (e) {
				$(this).parent().css('position', 'static');
				$(this).css('right', '25px'); // Row border width
			})
			.on('sticky_kit:unbottom', function (e) {
				$(this).parent().css('position', 'relative');
				$(this).css('right', 'auto');
			});
	}

	// Image gallery
	$('.image-gallery').each(async function () {
		var $this = $(this),
			slides = $this.find('.slides');

		if (slides.children('.slide').length <= 1) {
			// Need more than 1 image for an image gallery
			return;
		}

		const Swiper = (await import('swiper/dist/js/swiper.js')).default;

		var thumbs = $this.find('.thumbs'),
			thumbsSwiper = null;

		if (thumbs.length) {
			wrapSwiper(thumbs, '.thumb');

			thumbsSwiper = new Swiper(thumbs, {
				spaceBetween: 25, // Small gutter
				slidesPerView: 2,
				freeMode: true,
				watchSlidesVisibility: true,
				watchSlidesProgress: true,
				breakpointsInverse: true,
				breakpoints: {
					768: {
						slidesPerView: 3
					},
					1024: {
						slidesPerView: 4
					},
					1200: {
						slidesPerView: 3
					}
				}
			});
		}

		wrapSwiper(slides, '.slide', false, true);

		var slidesSwiper = new Swiper(slides, {
			effect: 'fade',
			loop: true,
			keyboard: true,
			autoplay: true,
			thumbs: {
				swiper: thumbsSwiper
			},
			navigation: {
				nextEl: '.swiper-button-next',
				prevEl: '.swiper-button-prev'
			},
		});

		hoverSwiper($this, slidesSwiper);
	});

	// Highlighted slider
	$('.highlighted-blocks').each(async function () {
		const Swiper = (await import('swiper/dist/js/swiper.js')).default;
		var $this = $(this);

		wrapSwiper($this, '.highlighted-block', true, true);

		var swiper = new Swiper(this, {
			effect: 'fade',
			speed: 1000,
			slidesPerView: 1,
			keyboard: true,
			autoplay: true,
			loop: true,
			pagination: {
				el: '.swiper-pagination',
				clickable: true
			},
			navigation: {
				nextEl: '.swiper-button-next',
				prevEl: '.swiper-button-prev'
			},
		});
	});

	// Sliders
	$('.slider').each(async function () {
		const Swiper = (await import('swiper/dist/js/swiper.js')).default;
		var $this = $(this);

		wrapSwiper($this, '.slide', true, false);

		// Remove block row classes
		var blockClasses = $.map(['xxs', 'xs', 's', 'm', 'l', 'xl', 'xxl'], function (bp) {
			return $.map([1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12], function (cols) {
				return 'block-row-' + bp + '-' + cols;
			}).join(' ');
		}).join(' ');
		$this.removeClass(blockClasses);

		var swiper = new Swiper(this, {
			speed: 1000,
			spaceBetween: 40, // 2rem
			slidesPerView: 1,
			keyboard: true,
			pagination: {
				el: '.swiper-pagination',
				clickable: true
			},
			breakpointsInverse: true,
			breakpoints: {
				600: {
					slidesPerView: 2
				},
				992: {
					slidesPerView: 3
				}
			},
			autoplay: true
		});

		hoverSwiper($this, swiper);
	});

	// Tabs
	var tabs = $('.tabs');
	if (tabs.length) {
		await import("../../node_modules/responsive-bp/src/js/responsive.tabs.js");
	}

	// Jump to submitted form
	$('.umbraco-forms-form[id]:has(form)').each(function () {
		var $this = $(this),
			form = $this.find('form');

		form.attr('action', form.attr('action') + '#' + $this.attr('id'));
	});

	// Lightbox
	$('body:has([rel=lightbox])').each(async function () {
		await import(/* webpackChunkName: "lightgallery" */ 'lightgallery');
		await import(/* webpackChunkName: "lightgallery" */ 'lg-video');

		$(this).lightGallery({
			selector: '[rel=lightbox]',
			prevHtml: '<i class="icon icon-arrow-left"></i>',
			nextHtml: '<i class="icon icon-arrow-right"></i>',
			download: false,
			hash: false,
			videoMaxWidth: '1920px'
		});
	});

	// Lightbox floor plans
	$('main:has([rel=lightbox-floor-plan])').each(async function () {
		await import(/* webpackChunkName: "lightgallery" */ 'lightgallery');
		await import(/* webpackChunkName: "lightgallery" */ 'lg-video');

		$(this).lightGallery({
			selector: '[rel=lightbox-floor-plan]',
			prevHtml: '<i class="icon icon-arrow-left"></i>',
			nextHtml: '<i class="icon icon-arrow-right"></i>',
			download: false,
			hash: false
		});
	});

	// Smooth scroll
	$('a[href*=\\#]').click(function () {
		var target = $(this.hash);

		if (!target.length) {
			target = $('[name=' + this.hash.slice(1) + ']');
		}

		if (target.length) {
			$('html, body').animate({
				scrollTop: target.offset().top - $('.top').height() - 20
			}, 500);

			return false;
		}
	});
});